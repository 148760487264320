#root {
    background-color: var(--darkgray);
}

#App {
    max-width: 1500px;
    height: 100vh;
}

#editor-background {
    display: flex;
    align-content: flex-end;
    flex-direction: column;
    padding-bottom: 10px;
}

#controlColumn {
    background-color: var(--lightgray);
    color: white;
    height: 100vh;
}

.specialborder {
    border-right: 4px var(--color2) solid;
}

#codeColumn {
  background-color: var(--darkgray);
}

#processorName {
  background-color: var(--darkgray);
}

.editor-class {
    flex-grow: 1;
}

.bp3-dark .bp3-slider-handle {
    background-color: var(--bs-gray-500) !important;
}

.bp3-dark .bp3-control .bp3-control-indicator {
    background-color: var(--bs-gray-500) !important;
}

#title {
    justify-content: center;
    background-color: var(--darkgray);
}

#controlRow {
    padding-top: 10px;
    background-color: var(--lightgray);
}

#controlButtonsRow {
    margin-bottom: 10px;
}

#speedRow {
    text-align: center;
}

.tapeRow {
    height: 80px;
}
