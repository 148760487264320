@import '~@blueprintjs/core/lib/css/blueprint.css';

:root {
  --lightgray: #394b59;
  --darkgray: #30404d;
  --gray1BP: #5C7080;
  --color2: #303744;
}

#root {
  overflow-y: hidden;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  /* background: #f1f1f1; */
  background: var(--darkgray);
  border-radius: 10px;
}

.moz-scroller {
  scrollbar-color: var(--darkgray);
  scrollbar-width: thin;
}

#controlColumn ::-webkit-scrollbar-track {
  /* background: #f1f1f1; */
  background: var(--lightgray);
  border-radius: 10px;
}


/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
