@keyframes highlight {
    0% {
        background: #ffffe2af;
    }
    100% {
        background: var(--bs-table-bg);
    }
}

#registers {
    overflow-y: scroll;
}

.highlight {
    animation: highlight;
    animation-duration: 500ms;
}

.table-dark {
    --bs-table-bg: #30404d;
    border-color: var(--gray1BP);
}

.table>:not(:first-child) {
    border-top: none;
    border-top-width: 0;
    border-top-style: none;
}

#registers thead {
     border-color: var(--gray1BP);
}
