.row_corner_indicator {
    width: 70px !important;
    height: 2.1em !important;
}

.Spreadsheet {
    width: 100%;
}

.Spreadsheet__table {
    width: 100%;
}

.editor_class {
    height: 400px;
    overflow-y: scroll;
}

.Spreadsheet--dark-mode {
  background-color: var(--lightgray) !important;
}

#editor {
    /* tapeRow heigh: 80 px - App.css */
    margin-bottom: 20px;
    height: calc(100vh - 2 * 80px - 20px);

}
